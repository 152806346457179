<template>
  <div>
    <div>
      <div class="mini-tab-root tab-elevation1 tab-rounded"  style="margin: 10px; width:320px; float:right;">
        <div class="mini-tab-content">
            <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
              <div class="mini-tab-flex">
                  <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                    <span>
                      <i class="fas fa-chart-line"></i>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
                  <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, 160)">
                    <span>
                      <i class="fas fa-chart-pie"></i>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
              </div>
              <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}px;`"></span>
            </div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div class="row" v-if="miniTab.tab == 1">
      <div class="col-sm-6">
        <div style="margin-top: 10px;">
          <doughnut-chart
            :chartdata="this.doughnut"
            :options="options"
            v-if="fetched"
            :height="200"
          />
        </div>
      </div>
      <div class="col-sm-6" style="padding-right:30px;">
        <br>
        <br>
        <div class="row no-padding no-margin">
          <div class="col-sm-6 no-padding"></div>
          <div class="col-sm-3 no-padding"><strong>Porcentagem</strong></div>
          <div class="col-sm-3 text-right no-padding" style="padding-right:30px;"><strong>Números</strong></div>
        </div>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #1F77B4 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Chrome</span></div>
              <div class="col-sm-3 no-padding">{{this.percentChromeData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalChromeData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #AEC7E8 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Edge</span></div>
              <div class="col-sm-3 no-padding">{{this.percentEdgeData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalEdgeData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #FF7F0E !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">IE</span></div>
              <div class="col-sm-3 no-padding">{{this.percentIEData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalIEData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #FFBB78 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Mozilla</span></div>
              <div class="col-sm-3 no-padding">{{this.percentMozillaData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalMozillaData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #2CA02C !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Opera</span></div>
              <div class="col-sm-3 no-padding">{{this.percentOperaData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalOperaData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #FFCC00 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Safari</span></div>
              <div class="col-sm-3 no-padding">{{this.percentSafariData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalSafariData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #FF9896 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Outros</span></div>
              <div class="col-sm-3 no-padding">{{this.percentOthersData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalOthersData}}</div>
            </div>
          </li>
        </ul>
        <br>
        <br>
      </div>
    </div>
    <div v-if="miniTab.tab == 0">
      <charts
        :data="data"
        :loading="!fetched"
        :xAxis="form.time_window"
        title="Navegadores por Abertos/Clicks"
        @update="fetch" />
    </div>
  </div>
</template>

<script>
import MailService from '@/services/mail.service';
// import LineChart from '@/components/LineChart.vue';
// import DoughnutChart from '@/components/DoughnutChart.vue';
import Charts from '@/components/Charts.vue';
import moment from 'moment';
import DoughnutChart from '@/components/DoughnutChart.vue';

export default {
  name: 'BrowsersCard',
  // components: { LineChart, DoughnutChart },
  components: {
    Charts,
    DoughnutChart,
  },
  props: ['form', 'filters'],
  data() {
    return {
      percentChromeData: 0,
      percentEdgeData: 0,
      percentFirefoxData: 0,
      percentIEData: 0,
      percentMozillaData: 0,
      percentOperaData: 0,
      percentSafariData: 0,
      percentOthersData: 0,
      totalChromeData: 0,
      totalEdgeData: 0,
      totalFirefoxData: 0,
      totalIEData: 0,
      totalMozillaData: 0,
      totalOperaData: 0,
      totalSafariData: 0,
      totalOthersData: 0,
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      doughnut: {
        labels: [],
        datasets: [
          {
            data: [],
            label: [],
            backgroundColor: [],
          },
        ],
      },
      data: [],
      options: {
        responsive: true,
        legend: {
          display: false,
        },
      },
      fetched: false,
      expanded: false,
      type: 'pie',
      colors: ['#2fbfa0', '#ff5c75', '#ffc000'],
      total: 0,
      browsers: {},
      line: {
        chartdata: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'HH:mm:ss',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        },
      },
      pie: {
        chartdata: {
          labels: [],
          datasets: [
            {
              backgroundColor: ['#0f0', '#f00', '#00f'],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
    };
  },
  created() {
    // this.fetch();
    this.fetched = true;
    this.data = [];
  },
  methods: {
    fetch2() {
      this.fetched = false;
      this.line.chartdata.labels = [];
      this.line.chartdata.datasets = [];
      this.pie.chartdata.labels = [];
      this.pie.chartdata.datasets.backgroundColor = [];
      this.pie.chartdata.datasets.data = [];
      this.browsers = {};
      this.total = 0;

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      MailService.getEventInsights({
        filters: [
          {
            field: 'status',
            values: ['click'],
          },
        ],
        ...this.form,
        group_by: 'browser',
      })
        .then(
          (response) => {
            response.forEach((element) => {
              Object.keys(element.values).forEach((browser, i) => {
                const value = element.values[browser];
                if (!this.line.chartdata.datasets[i]) {
                  this.line.chartdata.datasets[i] = {
                    label: browser,
                    borderColor: this.colors[i],
                    data: [value],
                    fill: false,
                    lineTension: 0.1,
                  };
                  this.pie.chartdata.labels.push(browser);
                  this.pie.chartdata.datasets[0].backgroundColor[
                    i
                  ] = this.colors[i];
                  this.pie.chartdata.datasets[0].data[i] = value;
                  this.browsers[browser] = 0;
                  console.log(this.browsers);
                } else {
                  this.line.chartdata.datasets[i].data.push(value);
                  this.pie.chartdata.datasets[0].data[i] += value;
                }

                this.browsers[browser] += value;
                this.total += value;
              });
              this.line.chartdata.labels.push(element.date_time);
            });
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetch() {
      this.fetched = false;
      this.line.chartdata.labels = [];
      this.line.chartdata.datasets = [];
      this.pie.chartdata.labels = [];
      this.pie.chartdata.datasets.backgroundColor = [];
      this.pie.chartdata.datasets.data = [];
      this.browsers = {};
      this.total = 0;

      const begin = moment(this.form.begin_date, 'YYYY-MM-DD 00:00:00');
      const end = moment(this.form.end_date, 'YYYY-MM-DD 00:00:00');
      if (end.diff(begin, 'days') <= 1) {
        this.form.time_window = 'hourly';
      } else {
        this.form.time_window = 'daily';
      }
      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;
      this.doughnut.labels = [];
      this.doughnut.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];

      MailService.getEventInsights({
        filters: [
          {
            field: 'status',
            values: ['click'],
          },
          ...this.filters,
        ],
        ...this.form,
        group_by: 'browser',
      })
        .then(
          (response) => {
            console.log('Browser');
            console.log(response);

            this.doughnut.labels.push('Chrome %');
            this.doughnut.labels.push('Edge %');
            this.doughnut.labels.push('Firefox %');
            this.doughnut.labels.push('IE %');
            this.doughnut.labels.push('Mozilla %');
            this.doughnut.labels.push('Opera %');
            this.doughnut.labels.push('Safari %');
            this.doughnut.labels.push('Outros %');

            this.totalChromeData = 0;
            this.totalEdgeData = 0;
            this.totalFirefoxData = 0;
            this.totalIEData = 0;
            this.totalMozillaData = 0;
            this.totalOperaData = 0;
            this.totalSafariData = 0;
            this.totalOthersData = 0;

            let data = [
              ['x'],
              ['Chrome'],
              ['Edge'],
              ['Firefox'],
              ['IE'],
              ['Mozilla'],
              ['Opera'],
              ['Safari'],
              ['Outros'],
            ];
            const maxSeries = 18;
            let series = 0;
            let noData = true;

            response.forEach((element) => {
              const chromeData = element.values.Chrome ?? 0;
              const edgeData = element.values.Edge ?? 0;
              const firefoxData = element.values.Firefox ?? 0;
              const iEData = element.values.IE ?? 0;
              const mozillaData = element.values.Mozilla ?? 0;
              const operaData = element.values.Opera ?? 0;
              const safariData = element.values.Safari ?? 0;
              const otherData = element.values.other ?? 0;

              this.totalChromeData += chromeData;
              this.totalEdgeData += edgeData;
              this.totalFirefoxData += firefoxData;
              this.totalIEData += iEData;
              this.totalMozillaData += mozillaData;
              this.totalOperaData += operaData;
              this.totalSafariData += safariData;
              this.totalOthersData += otherData;

              if (!chromeData && !edgeData && !firefoxData && !iEData && !mozillaData && !operaData && !safariData && !otherData) {
                series += 1;
              } else {
                noData = false;
                series = 0;
              }

              if (series < maxSeries) {
                data[0].push(element.date_time);
                data[1].push(chromeData ?? 0);
                data[2].push(edgeData ?? 0);
                data[3].push(firefoxData ?? 0);
                data[4].push(iEData ?? 0);
                data[5].push(mozillaData ?? 0);
                data[6].push(operaData ?? 0);
                data[7].push(safariData ?? 0);
                data[8].push(otherData ?? 0);
              }
            });

            const total = this.totalChromeData + this.totalEdgeData + this.totalFirefoxData + this.totalIEData + this.totalMozillaData + this.totalOperaData + this.totalSafariData + this.totalOthersData;
            this.percentChromeData = this.totalChromeData > 0 ? (this.totalChromeData / total) * 100 : 0;
            this.percentEdgeData = this.totalEdgeData > 0 ? (this.totalEdgeData / total) * 100 : 0;
            this.percentFirefoxData = this.totalFirefoxData > 0 ? (this.totalFirefoxData / total) * 100 : 0;
            this.percentIEData = this.totalIEData > 0 ? (this.totalIEData / total) * 100 : 0;
            this.percentMozillaData = this.totalMozillaData > 0 ? (this.totalMozillaData / total) * 100 : 0;
            this.percentOperaData = this.totalOperaData > 0 ? (this.totalOperaData / total) * 100 : 0;
            this.percentSafariData = this.totalSafariData > 0 ? (this.totalSafariData / total) * 100 : 0;
            this.percentOthersData = this.totalOthersData > 0 ? (this.totalOthersData / total) * 100 : 0;

            this.doughnut.datasets[0].data.push(this.percentChromeData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentEdgeData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentFirefoxData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentIEData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentMozillaData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentOperaData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentSafariData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentOthersData.toFixed(2));

            this.doughnut.datasets[0].backgroundColor.push('#1F77B4');
            this.doughnut.datasets[0].backgroundColor.push('#AEC7E8');
            this.doughnut.datasets[0].backgroundColor.push('#FF7F0E');
            this.doughnut.datasets[0].backgroundColor.push('#FF7F0E');
            this.doughnut.datasets[0].backgroundColor.push('#FFBB78');
            this.doughnut.datasets[0].backgroundColor.push('#2CA02C');
            this.doughnut.datasets[0].backgroundColor.push('#FFCC00');
            this.doughnut.datasets[0].backgroundColor.push('#FF9896');

            if (noData) {
              data = null;
              this.data = [];
            } else {
              this.data = data;
            }
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    changeChart() {
      this.type = this.type === 'pie' ? 'line' : 'pie';
    },
    expand() {
      this.expanded = !this.expanded;
    },
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
</style>
