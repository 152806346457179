<template>
  <div>
    <div>
      <div class="mini-tab-root tab-elevation1 tab-rounded"  style="margin: 10px; width:320px; float:right;">
        <div class="mini-tab-content">
            <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
              <div class="mini-tab-flex">
                  <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                    <span>
                      <i class="fas fa-chart-line"></i>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
                  <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, 160)">
                    <span>
                      <i class="fas fa-chart-pie"></i>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
              </div>
              <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}px;`"></span>
            </div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div class="row" v-if="miniTab.tab == 1">
      <div class="col-sm-6">
        <div style="margin-top: 10px;">
          <doughnut-chart
            :chartdata="this.doughnut"
            :options="options"
            v-if="fetched"
            :height="200"
          />
        </div>
      </div>
      <div class="col-sm-6" style="padding-right:30px;">
        <br>
        <br>
        <div class="row no-padding no-margin">
          <div class="col-sm-6 no-padding"></div>
          <div class="col-sm-3 no-padding"><strong>Porcentagem</strong></div>
          <div class="col-sm-3 text-right no-padding" style="padding-right:30px;"><strong>Números</strong></div>
        </div>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #1f77b4 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Gmail</span></div>
              <div class="col-sm-3 no-padding">{{this.percentGmailData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalGmailData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #aec7e8 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Linux</span></div>
              <div class="col-sm-3 no-padding">{{this.percentLinuxData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalLinuxData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #ff7f0e !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">OSX</span></div>
              <div class="col-sm-3 no-padding">{{this.percentOSXData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalOSXData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #2ca02c !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Windows</span></div>
              <div class="col-sm-3 no-padding">{{this.percentWindowData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalWindowData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #98df8a !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Yahoo</span></div>
              <div class="col-sm-3 no-padding">{{this.percentYahooData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalYahooData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #ffbb78 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Outros</span></div>
              <div class="col-sm-3 no-padding">{{this.percentOtherData.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalOtherData}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-sm-12"><br><br></div>
    </div>
    <div v-if="miniTab.tab == 0">
      <charts
        :data="data"
        :loading="!fetched"
        :xAxis="form.time_window"
        title="Webmail por Abertura"
        @update="fetch" />
    </div>
  </div>
</template>

<script>
import MailService from '@/services/mail.service';
// import LineChart from '@/components/LineChart.vue';
// import DoughnutChart from '@/components/DoughnutChart.vue';
import Charts from '@/components/Charts.vue';
import moment from 'moment';
import DoughnutChart from '@/components/DoughnutChart.vue';

export default {
  name: 'WebmailCard',
  components: {
    Charts,
    DoughnutChart,
  },
  props: ['form', 'filters'],
  data() {
    return {
      options: {
        responsive: true,
        legend: {
          display: false,
        },
      },
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      doughnut: {
        labels: [],
        datasets: [
          {
            data: [],
            label: [],
            backgroundColor: [],
          },
        ],
      },
      data: [],
      fetched: false,
      expanded: false,
      type: 'pie',
      colors: ['#2fbfa0', '#ff5c75', '#ffc000'],
      total: 0,
      devices: {},
      line: {
        chartdata: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'HH:mm:ss',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        },
      },
      pie: {
        chartdata: {
          labels: [],
          datasets: [
            {
              backgroundColor: ['#0f0', '#f00', '#00f'],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
      totalGmailData: 0,
      totalLinuxData: 0,
      totalOSXData: 0,
      totalWindowData: 0,
      totalYahooData: 0,
      totalOtherData: 0,
      percentGmailData: 0,
      percentLinuxData: 0,
      percentOSXData: 0,
      percentWindowData: 0,
      percentYahooData: 0,
      percentOtherData: 0,
    };
  },
  created() {
    // this.fetch();
    this.fetched = true;
    this.data = [];
  },
  methods: {
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
    },
    fetch2() {
      this.fetched = false;
      this.line.chartdata.labels = [];
      this.line.chartdata.datasets = [];
      this.pie.chartdata.labels = [];
      this.pie.chartdata.datasets.backgroundColor = [];
      this.pie.chartdata.datasets.data = [];
      this.devices = {};
      this.total = 0;

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      MailService.getEventInsights({
        filters: [
          {
            field: 'status',
            values: ['open'],
          },
          {
            field: 'device_type',
            values: ['Webmail'],
          },
        ],
        ...this.form,
        group_by: 'platform',
      })
        .then(
          (response) => {
            const devices = {};
            let total = 0;
            response.forEach((element) => {
              Object.keys(element.values).forEach((device, i) => {
                const value = element.values[device];
                if (!this.line.chartdata.datasets[i]) {
                  this.line.chartdata.datasets[i] = {
                    label: device,
                    borderColor: this.colors[i],
                    data: [value],
                    fill: false,
                    lineTension: 0.1,
                  };
                  this.pie.chartdata.labels.push(device);
                  this.pie.chartdata.datasets[0].backgroundColor[
                    i
                  ] = this.colors[i];
                  this.pie.chartdata.datasets[0].data[i] = value;
                  devices[device] = 0;
                  console.log(this.devices);
                } else {
                  this.line.chartdata.datasets[i].data.push(value);
                  this.pie.chartdata.datasets[0].data[i] += value;
                }

                devices[device] += value;
                total += value;
              });
              this.line.chartdata.labels.push(element.date_time);
            });
            this.devices = devices;
            this.total = total;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetch() {
      this.doughnut.labels = [];
      this.doughnut.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];
      this.fetched = false;

      const begin = moment(this.form.begin_date, 'YYYY-MM-DD 00:00:00');
      const end = moment(this.form.end_date, 'YYYY-MM-DD 00:00:00');
      if (end.diff(begin, 'days') <= 1) {
        this.form.time_window = 'hourly';
      } else {
        this.form.time_window = 'daily';
      }

      MailService.getEventInsights({
        filters: [
          {
            field: 'status',
            values: ['open'],
          },
          {
            field: 'device_type',
            values: ['Webmail'],
          },
          ...this.filters,
        ],
        ...this.form,
        group_by: 'platform',
      })
        .then(
          (response) => {
            const maxSeries = 15;
            let series = 0;
            let noData = true;
            let data = [
              ['x'],
              ['Gmail'],
              ['Linux'],
              ['OSX'],
              ['Windows'],
              ['Yahoo'],
              ['Outros'],
            ];
            console.log('Web Mail');
            console.log(response);

            this.doughnut.labels.push('Gmail %');
            this.doughnut.labels.push('Linux %');
            this.doughnut.labels.push('OSX %');
            this.doughnut.labels.push('Windows %');
            this.doughnut.labels.push('Yahoo %');
            this.doughnut.labels.push('Outros %');

            this.totalGmailData = 0;
            this.totalLinuxData = 0;
            this.totalOSXData = 0;
            this.totalWindowData = 0;
            this.totalYahooData = 0;
            this.totalOtherData = 0;

            response.forEach((element) => {
              const gmailData = element.values.Gmail ?? 0;
              const linuxData = element.values.Linux ?? 0;
              const osxData = element.values.OSX ?? 0;
              const windowData = element.values.Windows ?? 0;
              const yahooData = element.values.Yahoo ?? 0;
              const otherData = element.values.other ?? 0;

              this.totalGmailData += gmailData;
              this.totalLinuxData += linuxData;
              this.totalOSXData += osxData;
              this.totalWindowData += windowData;
              this.totalYahooData += yahooData;
              this.totalOtherData += otherData;

              if (!gmailData && !linuxData && !osxData && !windowData && !yahooData && !otherData) {
                series += 1;
              } else {
                noData = false;
                series = 0;
              }

              if (series < maxSeries) {
                data[0].push(element.date_time);
                data[1].push(gmailData ?? 0);
                data[2].push(linuxData ?? 0);
                data[3].push(osxData ?? 0);
                data[4].push(windowData ?? 0);
                data[5].push(yahooData ?? 0);
                data[6].push(otherData ?? 0);
              }
            });

            // Data set
            const total = this.totalGmailData + this.totalLinuxData + this.totalOSXData + this.totalWindowData + this.totalYahooData + this.totalOtherData;
            this.percentGmailData = this.totalGmailData > 0 ? (this.totalGmailData / total) * 100 : 0;
            this.percentLinuxData = this.totalLinuxData > 0 ? (this.totalLinuxData / total) * 100 : 0;
            this.percentOSXData = this.totalOSXData > 0 ? (this.totalOSXData / total) * 100 : 0;
            this.percentWindowData = this.totalWindowData > 0 ? (this.totalWindowData / total) * 100 : 0;
            this.percentYahooData = this.totalYahooData > 0 ? (this.totalYahooData / total) * 100 : 0;
            this.percentOtherData = this.totalOtherData > 0 ? (this.totalOtherData / total) * 100 : 0;

            this.doughnut.datasets[0].data.push(this.percentGmailData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentLinuxData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentOSXData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentWindowData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentYahooData.toFixed(2));
            this.doughnut.datasets[0].data.push(this.percentOtherData.toFixed(2));

            this.doughnut.datasets[0].backgroundColor.push('#1f77b4');
            this.doughnut.datasets[0].backgroundColor.push('#aec7e8');
            this.doughnut.datasets[0].backgroundColor.push('#ff7f0e');
            this.doughnut.datasets[0].backgroundColor.push('#2ca02c');
            this.doughnut.datasets[0].backgroundColor.push('#98df8a');
            this.doughnut.datasets[0].backgroundColor.push('#ffbb78');

            if (noData) {
              data = null;
              this.data = [];
            } else {
              this.data = data;
            }
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    changeChart() {
      this.type = this.type === 'pie' ? 'line' : 'pie';
    },
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
</style>
