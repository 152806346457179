<template>
  <div class="content">
    <PageHeader :title="$t('sms.infos.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <span class="m-t-2 d-inline-block col-8">{{$t('generic-str.filter')}}</span>
                <div class="col-4 text-right m-left" v-if="!this.$route.query.campaign_id">
                  <date-range-picker
                    class="form-control mr-sm-2"
                    value
                    :options="options"
                    @change="changeDate"
                  />
                </div>
              </div>
            </div>
            <div class="card-body" v-if="fetched">
              <div class="form-row hide-m">
                <div class="col-lg-3 col-md-3 col-sm-12">
                  <label>{{$t('sms.infos.filters.field.label')}}</label>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12">
                  <label>{{$t('sms.infos.lbl-value-text')}}</label>
                  x
                </div>
              </div>
              <div v-for="(filter, n) in filters" class="form-row" :key="n">
                <div class="form-group col-lg-3 col-md-3 col-sm-12">
                  <select-input
                    v-model="filters[n].field"
                    :reduce="(field) => field.value"
                    :options="fields"
                    :clearable="true"
                  />
                </div>
                <div class="form-group col-lg-8 col-md-8 col-sm-12">
                  <select-input
                    v-if="filters[n].field == 'status'"
                    multiple
                    :reduce="(field) => field.value"
                    v-model="filters[n].values"
                    :options="status"
                    :clearable="true"
                  />
                  <select-input
                    v-if="filters[n].field == 'source'"
                    multiple
                    :reduce="(field) => field.value"
                    v-model="filters[n].values"
                    :options="typeSends"
                    :clearable="true"
                  />
                  <select-input
                    v-else-if="filters[n].field == 'campaign_id'"
                    multiple
                    label="name"
                    :reduce="(field) => field.id"
                    v-model="filters[n].values"
                    @input="changeCampaigns"
                    :options="campaigns"
                    :clearable="true"
                  />
                  <select-input
                    v-else-if="
                      filters[n].field == 'to' ||
                      filters[n].field == 'segments' ||
                      filters[n].field == 'from'
                    "
                    multiple
                    taggable
                    v-model="filters[n].values"
                    :clearable="true"
                  />
                  <select-input
                    v-else-if="filters[n].field == 'account_id'"
                    multiple
                    label="name"
                    :reduce="(field) => field.id"
                    v-model="filters[n].values"
                    :options="customers"
                    :clearable="true"
                  />
                  <input
                    v-else
                    type="text"
                    class="form-control"
                    :placeholder="$t('sms.infos.lbl-select')"
                    disabled
                  />
                </div>
                <div class="form-group col-1">
                  <button
                    @click="removeFilter(n)"
                    class="btn btn-danger btn-sm"
                  >
                    <i class="icon dripicons-trash color-white"></i>
                  </button>
                </div>
              </div>
            </div>
            <div v-else class="qt-block-ui relative" style="padding:120px;" />
            <div class="card-footer d-flex justify-content-between">
              <button @click="addFilter" type="button" class="btn btn-primary">
                {{$t('sms.infos.lbl-add-filter')}}
              </button>
              <button @click="fetchAll" type="button" class="btn btn-success">
                {{$t('generic-str.filter')}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card allCharts" :class="{ booted }">
        <b-tabs fill v-model="tabIndex">
          <b-tab active>
            <template #title>
              <span>{{$tc('generic-str.sent-a', 2)}}</span>
            </template>
            <tariffed-card ref="tariffed" :form="form" :filters="filters" />
          </b-tab>
          <b-tab @click="goToOutgoing">
            <template #title>
              <span>Eventos</span>
            </template>
            <outgoing-card ref="outgoing" :form="form" :filters="filters" />
          </b-tab>
          <b-tab @click="goToDevices">
            <template #title>
              <span>{{$tc('generic-str.device', 2)}}</span>
            </template>
            <devices-card ref="devices" :form="form" :filters="filters" />
          </b-tab>
          <b-tab @click="goToWebmail">
            <template #title>
              <span>Provedor</span>
            </template>
            <webmail-card ref="webmail" :form="form" :filters="filters" />
          </b-tab>
          <b-tab @click="goToPhones">
            <template #title>
              <span>{{$tc('generic-str.tel', 2)}}</span>
            </template>
            <phones-card ref="phones" :form="form" :filters="filters" />
          </b-tab>
          <b-tab @click="goToBrowsers">
            <template #title>
              <span>{{$tc('generic-str.browser', 2)}}</span>
            </template>
            <browsers-card ref="browsers" :form="form" :filters="filters" />
          </b-tab>
        </b-tabs>
      <!--
        <div class="col-lg-12 col-xl-12">
          <tariffed-card ref="tariffed" :form="form" :filters="filters" />
        </div>
        <div class="col-lg-12 col-xl-12">
          <outgoing-card ref="outgoing" :form="form" :filters="filters" />
        </div>
        <div class="col-12 col-md-6">
          <devices-card ref="devices" :form="form" :filters="filters" />
        </div>
        <div class="col-12 col-md-6">
          <webmail-card ref="webmail" :form="form" :filters="filters" />
        </div>
        <div class="col-12 col-md-6">
          <phones-card ref="phones" :form="form" :filters="filters" />
        </div>
        <div class="col-12 col-md-6">
          <browsers-card ref="browsers" :form="form" :filters="filters" />
        </div>
        -->
      </div>

      <!--
      <div>
        <charts :loading="true" />
      </div>
      -->
    </div>
  </div>
</template>

<script>
// import Charts from '@/components/Charts.vue';

// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import SelectInput from '@/components/SelectInput.vue';
import OutgoingCard from '@/components/mails/insights/OutgoingCard.vue';
import TariffedCard from '@/components/mails/insights/TariffedCard.vue';
import DevicesCard from '@/components/mails/insights/DevicesCard.vue';
import WebmailCard from '@/components/mails/insights/WebmailCard.vue';
import PhonesCard from '@/components/mails/insights/PhonesCard.vue';
import BrowsersCard from '@/components/mails/insights/BrowsersCard.vue';
import MailService from '@/services/mail.service';
import $ from 'jquery';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    SelectInput,
    OutgoingCard,
    TariffedCard,
    DevicesCard,
    WebmailCard,
    PhonesCard,
    BrowsersCard,

    // Charts,
  },
  /* created() {
    if (this.$route.query.campaign_id) {
      this.form.begin_date = '';
      this.form.end_date = '';
    }
  }, */
  data() {
    return {
      tabIndex: 0,
      booted: false,
      fetched: false,
      outgoingDone: false,
      devicesDone: false,
      webmailDone: false,
      phonesDone: false,
      browsersDone: false,
      filtered: false,
      dateRange: {},
      dateType: 'day',
      campaigns: [],
      fields: [
        { label: this.$t('generic-str.group'), value: 'group' },
        { label: 'Status', value: 'status' },
        { label: this.$t('generic-str.subject'), value: 'subject' },
        { label: this.$t('generic-str.lbl-recipient'), value: 'to' },
        { label: this.$t('generic-str.lbl-sender'), value: 'from' },
        { label: this.$t('sms.infos.filters.field.lbl-campaign'), value: 'campaign_id' },
        { label: this.$t('mail-component.send-type'), value: 'source' },
      ],
      typeSends: [
        { label: 'API', value: 'api' },
        { label: this.$t('sms.infos.filters.field.lbl-campaign'), value: 'campaign' },
        { label: this.$t('sms.send-msg.tabs.simple.title'), value: 'simple' },
      ],
      status: [
        { label: this.$t('sms.infos.filters.status.lbl-pending'), value: 'accepted' },
        { label: this.$t('sms.infos.filters.status.lbl-queue'), value: 'queued' },
        { label: this.$t('sms.infos.filters.status.lbl-sending'), value: 'sending' },
        { label: this.$t('sms.infos.filters.status.lbl-sent'), value: 'sent' },
        { label: this.$t('sms.infos.filters.status.lbl-error'), value: 'failed' },
        { label: this.$t('sms.infos.filters.status.lbl-delivered'), value: 'delivered' },
        { label: this.$tc('generic-str.status.lbl-open', 1), value: 'opened' },
      ],
      form: {
        begin_date: moment().format('YYYY-MM-DD 00:00:00'),
        end_date: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
        time_window: 'hourly',
      },
      range: [moment().format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 00:00:00')],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: this.$t('generic-str.cancel'),
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      filters: [
        {
          field: this.$route.query.campaign_id ? 'campaign_id' : '',
          values: this.$route.query.campaign_id
            ? [this.$route.query.campaign_id]
            : null,
        },
      ],
    };
  },
  created() {
    console.log(this.$route.params.from);
    this.fetchCampaigns();
    if (this.$route.query.from) {
      this.form.begin_date = moment(this.$route.query.from).format('YYYY-MM-DD 00:00:00');
      this.form.end_date = moment().add(1, 'days').format('YYYY-MM-DD 00:00:00');
      this.range = [moment(this.$route.query.from).format('DD/MM/YYYY'), moment().format('DD/MM/YYYY')];
      console.log(this.range);
      this.options.startDate = moment(this.$route.query.from).format('DD/MM/YYYY');
      this.options.endDate = moment().format('DD/MM/YYYY');
      this.$forceUpdate();
    }
  },
  methods: {
    changeCampaigns(campaign) {
      console.log(campaign);
    },
    changeDate(date) {
      console.log('date>>', date, !!date);
      if (date) {
        this.form.begin_date = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.form.end_date = moment(date[1], 'DD/MM/YYYY').add(1, 'days').format(
          'YYYY-MM-DD 00:00:00',
        );
      }
    },
    fetchCampaigns() {
      MailService.getCampaigns().then(
        (campaigns) => {
          this.campaigns = campaigns;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchAll() {
      this.booted = true;

      console.log('this.tabIndex', this.tabIndex);

      this.tariffedDone = false;
      this.outgoingDone = false;
      this.devicesDone = false;
      this.webmailDone = false;
      this.phonesDone = false;
      this.browsersDone = false;

      switch (this.tabIndex) {
        case 0:
          this.goToTariffed();
          break;
        case 1:
          this.goToOutgoing();
          break;
        case 2:
          this.goToDevices();
          break;
        case 3:
          this.goToWebmail();
          break;
        case 4:
          this.goToPhones();
          break;
        case 5:
          this.goToBrowsers();
          break;
        default:
          console.error('Invalid tabIndex');
      }
      if (!this.filtered) this.filtered = true;
    },
    goToTariffed() {
      if (!this.tariffedDone) {
        this.tariffedDone = true;
        this.$refs.tariffed.fetch();
      }
    },
    goToOutgoing() {
      if (!this.outgoingDone) {
        this.outgoingDone = true;
        this.$refs.outgoing.fetch();
      }
    },
    goToDevices() {
      if (!this.devicesDone) {
        this.devicesDone = true;
        this.$refs.devices.fetch();
      }
    },
    goToWebmail() {
      if (!this.webmailDone) {
        this.webmailDone = true;
        this.$refs.webmail.fetch();
      }
    },
    goToPhones() {
      if (!this.phonesDone) {
        this.phonesDone = true;
        this.$refs.phones.fetch();
      }
    },
    goToBrowsers() {
      if (!this.browsersDone) {
        this.browsersDone = true;
        this.$refs.browsers.fetch();
      }
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
  },
};
</script>
<style scoped lang="scss">
.allCharts {
  display: none;

  &.booted {
    display: block;
  }
}
.allCharts::v-deep {
  .card {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }

  .nav-tabs {
    margin: 0;
  }

  .nav-tabs .nav-item .nav-link {
    padding-bottom: 1.5em;
    padding-top: 1.5em;
  }
}
</style>
