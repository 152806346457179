<template>
  <!-- <div class="card" :class="{ 'card-expand': expanded }">
    <div class="card-header">
      Telefones por Abertura
      <ul class="actions top-right">
        <li>
          <a href="javascript:void(0)" @click="changeChart">
            <i v-if="type == 'line'" class="icon dripicons-graph-pie"></i>
            <i v-else-if="type == 'pie'" class="icon dripicons-graph-line"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="fetch">
            <i class="icon dripicons-clockwise"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="expand">
            <i v-if="expanded" class="icon dripicons-contract-2"></i>
            <i v-else class="icon dripicons-expand-2"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class>
      <div class="card-body block-el">
        <div class="row m-0 col-border-xl" v-if="fetched">
          <div class="col-2 col-md-3 p-20">
            <h6>Total</h6>
            <span class="h3 text-primary">{{ total }}</span>
            <span v-for="(value, device) in devices" :key="device">
              <h6 class="m-t-20">{{ device }}</h6>
              <span class="h3 text-success">{{ value }}</span>
            </span>
          </div>
          <div class="col-10 col-md-9">
            <template v-if="total">
              <line-chart
                v-if="type === 'line'"
                :chartdata="line.chartdata"
                :options="line.options"
                :height="300"
              />
              <doughnut-chart
                v-else-if="type === 'pie'"
                :chartdata="pie.chartdata"
                :options="pie.options"
                :height="300"
              />
            </template>
            <div v-else class="text-center table-placeholder">
              <i class="far fa-chart-bar font-size-80"></i>
              <h5 class="card-title m-t-20">{{$t('sms.infos.none')}}</h5>
              <p>{{$t('sms.infos.none-msg')}}</p>
            </div>
          </div>
        </div>
        <div v-else class="qt-block-ui" />
      </div>
    </div>
  </div> -->
  <div>
    <div>
      <div class="mini-tab-root tab-elevation1 tab-rounded"  style="margin: 10px; width:320px; float:right;">
        <div class="mini-tab-content">
            <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
              <div class="mini-tab-flex">
                  <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                    <span>
                      <i class="fas fa-chart-line"></i>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
                  <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, 160)">
                    <span>
                      <i class="fas fa-chart-pie"></i>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
              </div>
              <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}px;`"></span>
            </div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div class="row" v-if="miniTab.tab == 1">
      <div class="col-sm-6">
        <div>
          <doughnut-chart
            :chartdata="this.doughnut"
            :options="options"
            v-if="fetched"
            :height="150"
          />
        </div>
      </div>
      <div class="col-sm-6" style="padding-right:30px;">
        <br>
        <br>
        <div class="row no-padding no-margin">
          <div class="col-sm-6 no-padding"></div>
          <div class="col-sm-3 no-padding"><strong>Porcentagem</strong></div>
          <div class="col-sm-3 text-right no-padding" style="padding-right:30px;"><strong>Números</strong></div>
        </div>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #1F77B4 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Android</span></div>
              <div class="col-sm-3 no-padding">{{this.androidPercent.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalAndroidData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #FF7F0E !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Windows Phone</span></div>
              <div class="col-sm-3 no-padding">{{this.windowsPercent.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalWindowData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #AEC7E8 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">iOS</span></div>
              <div class="col-sm-3 no-padding">{{this.iosPercent.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totaliOSData}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-sm-12"><br><br></div>
    </div>
    <div v-if="miniTab.tab == 0">
      <charts
        :data="data"
        :loading="!fetched"
        :xAxis="form.time_window"
        title="Telefones por Abertura"
        @update="fetch" />
    </div>
  </div>
</template>

<script>
import MailService from '@/services/mail.service';
// import LineChart from '@/components/LineChart.vue';
import DoughnutChart from '@/components/DoughnutChart.vue';
import Charts from '@/components/Charts.vue';
import moment from 'moment';

export default {
  name: 'PhonesCard',
  // components: { LineChart, DoughnutChart },
  components: {
    Charts,
    DoughnutChart,
  },
  props: ['form', 'filters'],
  data() {
    return {
      options: {
        responsive: true,
        legend: {
          display: false,
        },
      },
      androidPercent: 0,
      iosPercent: 0,
      windowsPercent: 0,
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      data: [],
      fetched: false,
      expanded: false,
      type: 'pie',
      colors: ['#2fbfa0', '#ff5c75', '#ffc000'],
      total: 0,
      devices: {},
      doughnut: {
        labels: [],
        datasets: [
          {
            data: [],
            label: [],
            backgroundColor: [],
          },
        ],
      },
      line: {
        chartdata: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'HH:mm:ss',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        },
      },
      pie: {
        chartdata: {
          labels: [],
          datasets: [
            {
              backgroundColor: ['#0f0', '#f00', '#00f'],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
      totalAndroidData: 0,
      totalWindowData: 0,
      totaliOSData: 0,
    };
  },
  created() {
    // this.fetch();
    this.fetched = true;
    this.data = [];
  },
  methods: {
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
    },
    fetch2() {
      this.fetched = false;
      this.line.chartdata.labels = [];
      this.line.chartdata.datasets = [];
      this.pie.chartdata.labels = [];
      this.pie.chartdata.datasets.backgroundColor = [];
      this.pie.chartdata.datasets.data = [];
      this.devices = {};
      this.total = 0;

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      MailService.getEventInsights({
        filters: [
          {
            field: 'status',
            values: ['open'],
          },
          {
            field: 'device_type',
            values: ['Phone'],
          },
        ],
        ...this.form,
        group_by: 'platform',
      })
        .then(
          (response) => {
            const devices = {};
            let total = 0;
            response.forEach((element) => {
              Object.keys(element.values).forEach((device, i) => {
                const value = element.values[device];
                if (!this.line.chartdata.datasets[i]) {
                  this.line.chartdata.datasets[i] = {
                    label: device,
                    borderColor: this.colors[i],
                    data: [value],
                    fill: false,
                    lineTension: 0.1,
                  };
                  this.pie.chartdata.labels.push(device);
                  this.pie.chartdata.datasets[0].backgroundColor[
                    i
                  ] = this.colors[i];
                  this.pie.chartdata.datasets[0].data[i] = value;
                  devices[device] = 0;
                  console.log(this.devices);
                } else {
                  this.line.chartdata.datasets[i].data.push(value);
                  this.pie.chartdata.datasets[0].data[i] += value;
                }

                devices[device] += value;
                total += value;
              });
              this.line.chartdata.labels.push(element.date_time);
            });
            this.devices = devices;
            this.total = total;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetch() {
      this.fetched = false;
      this.doughnut.labels = [];
      this.doughnut.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];

      const begin = moment(this.form.begin_date, 'YYYY-MM-DD 00:00:00');
      const end = moment(this.form.end_date, 'YYYY-MM-DD 00:00:00');
      if (end.diff(begin, 'days') <= 1) {
        this.form.time_window = 'hourly';
      } else {
        this.form.time_window = 'daily';
      }

      MailService.getEventInsights({
        filters: [
          {
            field: 'status',
            values: ['open'],
          },
          {
            field: 'device_type',
            values: ['Phone'],
          },
          ...this.filters,
        ],
        ...this.form,
        group_by: 'platform',
      }).then((response) => {
        let data = [
          ['x'],
          ['Android'], // androidData
          ['WindowsPhoneOS'], // windowData
          ['iOS'], // iosData
        ];
        const maxSeries = 15;
        let series = 0;
        let noData = true;

        this.doughnut.labels.push('Android %');
        this.doughnut.labels.push('WindowsPhoneOS %');
        this.doughnut.labels.push('iOS %');

        this.totalAndroidData = 0;
        this.totalWindowData = 0;
        this.totaliOSData = 0;

        response.forEach((element) => {
          const androidData = element.values.Android ?? 0;
          const windowData = element.values.WindowsPhoneOS ?? 0;
          const iOSData = element.values.iOS ?? 0;

          this.totalAndroidData += androidData;
          this.totalWindowData += windowData;
          this.totaliOSData += iOSData;

          if (!androidData && !windowData && !iOSData) {
            series += 1;
          } else {
            noData = false;
            series = 0;
          }

          if (series < maxSeries) {
            data[0].push(element.date_time);
            data[1].push(androidData ?? 0);
            data[2].push(windowData ?? 0);
            data[3].push(iOSData ?? 0);
          }
        });

        const total = this.totalAndroidData + this.totalWindowData + this.totaliOSData;
        this.androidPercent = this.totalAndroidData > 0 ? (this.totalAndroidData / total) * 100 : 0;
        this.windowsPercent = this.totalWindowData > 0 ? (this.totalWindowData / total) * 100 : 0;
        this.iosPercent = this.totaliOSData > 0 ? (this.totaliOSData / total) * 100 : 0;

        this.doughnut.datasets[0].data.push(this.androidPercent.toFixed(2));
        this.doughnut.datasets[0].data.push(this.windowsPercent.toFixed(2));
        this.doughnut.datasets[0].data.push(this.iosPercent.toFixed(2));

        this.doughnut.datasets[0].backgroundColor.push('#1F77B4');
        this.doughnut.datasets[0].backgroundColor.push('#FF7F0E');
        this.doughnut.datasets[0].backgroundColor.push('#AEC7E8');

        if (noData) {
          data = null;
          this.data = [];
        } else {
          this.data = data;
        }
      }, (error) => {
        this.content = error;
      }).finally(() => {
        this.fetched = true;
      });
    },
    changeChart() {
      this.type = this.type === 'pie' ? 'line' : 'pie';
    },
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
</style>
