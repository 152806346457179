<template>
  <!-- <div class="card" :class="{'card-expand':expanded}">
    <div class="card-header">
      {{$t('sms.infos.tariffed-component.title')}}
      <ul class="actions top-right">
        <li>
          <a href="javascript:void(0)" @click="changeChart">
            <i v-if="type == 'line'" class="icon dripicons-graph-pie"></i>
            <i v-else-if="type == 'pie'" class="icon dripicons-graph-line"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="fetch">
            <i class="icon dripicons-clockwise"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="expand">
            <i v-if="expanded" class="icon dripicons-contract-2"></i>
            <i v-else class="icon dripicons-expand-2"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="block-el">
      <template v-if="fetched">
        <div class="card-body">
          <template v-if="totalMessages || errorMessages">
            <line-chart
              v-if="type === 'line'"
              :chartdata="line.chartdata"
              :options="line.options"
              :height="120"
            />
            <doughnut-chart
              v-else-if="type === 'pie'"
              :chartdata="pie.chartdata"
              :options="pie.options"
              :height="163"
            />
          </template>
          <div v-else class="text-center table-placeholder">
            <i class="far fa-chart-bar font-size-80"></i>
            <h5 class="card-title m-t-20">{{$t('sms.infos.none')}}</h5>
            <p>{{$t('warnings.msg-text-warning')}}</p>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-6">
              <div class="card bg-success text-center p-3 m-b-0">
                <h5 class="text-white info-home">{{$tc('generic-str.tariffed-a', 2)}}</h5>
                <span class="text-white font-size-40 font-w-300">{{ totalMessages }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="card bg-danger text-center p-3 m-b-0">
                <h5 class="text-white info-home">{{$t('generic-str.status.lbl-errors')}}</h5>
                <span class="text-white font-size-40 font-w-300">{{ errorMessages }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="qt-block-ui" />
    </div>
  </div> -->
  <div>
    <ul class="panels stats">
      <li class="requests request" role="requests">
        <h2>Tarifadas</h2>
        <div class="primary" style="color: #00703c">
          {{ tarifedPercent.toFixed(2) }}%
        </div>
        <div class="secondary">{{ tariffedMessages }}</div>
      </li>
      <li class="delivered deliver" role="delivered">
        <h2>Erros</h2>
        <div class="primary" style="color: #cf202a">
          {{ errosPercent.toFixed(2) }}%
        </div>
        <div class="secondary">{{ errorMessages }}</div>
      </li>
      <li class="opens open" role="opens">
        <h2>Total</h2>
        <div class="primary" style="color: #ff7f0e">{{ totalMessages }}</div>
      </li>
    </ul>
    <charts
      :data="data"
      :loading="!fetched"
      :xAxis="form.time_window"
      :title="$t('sms.infos.tariffed-component.title')"
      @update="fetch"
    />
  </div>
</template>

<script>
import MailService from '@/services/mail.service';
// import LineChart from '@/components/LineChart.vue';
// import DoughnutChart from '@/components/DoughnutChart.vue';
import Charts from '@/components/Charts.vue';
import moment from 'moment';

export default {
  name: 'TariffedCard',
  // components: { LineChart, DoughnutChart },
  components: { Charts },
  props: ['form', 'filters'],
  data() {
    return {
      data: [],
      fetched: true,
      expanded: false,
      tarifedPercent: 0,
      errosPercent: 0,
      total: 0,
      type: 'line',
      tariffedMessages: 0,
      successMessages: 0,
      errorMessages: 0,
      totalMessages: 0,
      line: {
        chartdata: {
          labels: [],
          datasets: [
            {
              label: this.$tc('generic-str.tariffed-a', 2),
              borderColor: '#2fbfa0',
              data: [],
              fill: false,
              lineTension: 0.1,
            },
            {
              label: this.$t('generic-str.status.lbl-errors'),
              borderColor: '#ff5c75',
              data: [],
              fill: false,
              lineTension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'HH:mm:ss',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        },
      },
      pie: {
        chartdata: {
          labels: [
            this.$tc('generic-str.tariffed-a', 2),
            this.$t('generic-str.status.lbl-errors'),
          ],
          datasets: [
            {
              backgroundColor: ['#2fbfa0', '#ff5c75'],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
    };
  },
  created() {
    // this.fetch();
    // this.fetch2();
    this.fetched = true;
    this.data = [];
  },
  methods: {
    fetch() {
      console.log('FETCH');
      this.tarifedPercent = 0;
      this.errosPercent = 0;
      this.tariffedMessages = 0;
      this.successMessages = 0;
      this.errorMessages = 0;
      this.totalMessages = 0;

      this.fetched = false;

      const begin = moment(this.form.begin_date, 'YYYY-MM-DD 00:00:00');
      const end = moment(this.form.end_date, 'YYYY-MM-DD 00:00:00');
      if (end.diff(begin, 'days') <= 1) {
        this.form.time_window = 'hourly';
      } else {
        this.form.time_window = 'daily';
      }
      console.log('Dias');
      console.log(end.diff(begin, 'days'));
      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      Promise.all([
        MailService.getInsights({
          direction: 'outbound',
          filters: [
            {
              field: 'status',
              values: [
                'blocked',
                'bounced',
                'sent',
                'dropped',
                'accepted',
                'delivered',
                'opened',
              ],
            },
            ...this.filters,
          ],
          ...this.form,
        }),
        MailService.getInsights({
          direction: 'outbound',
          filters: [
            {
              field: 'status',
              values: ['failed'],
            },
            ...this.filters,
          ],
          ...this.form,
        }),
      ])
        .then(
          (responses) => {
            console.log(responses);
            const data = [
              ['x'],
              [this.$tc('generic-str.tariffed-a', 2)],
              [this.$t('generic-str.status.lbl-errors')],
              ['Total'],
            ];
            const maxSeries = 3;
            let series = 0;

            responses[0].forEach((element, index) => {
              const tariffed = element.values[0] ?? 0;
              const errors = responses[1][index].values[0] ?? 0;

              if (tariffed === 0 && errors === 0) {
                series += 1;
              } else {
                series = 0;
              }
              const total = tariffed + errors;
              if (series < maxSeries) {
                data[0].push(element.date_time);
                data[1].push(tariffed);
                data[2].push(errors);
                data[3].push(total);
              }

              this.tariffedMessages += tariffed;
              this.errorMessages += errors;
              this.totalMessages += total;
            });

            // errorMessages = '1';

            this.fetched = true;

            // console.log('DATA//', data);

            this.data = data[0][1] ? data : [];
            this.tarifedPercent =
              this.tariffedMessages > 0
                ? (this.tariffedMessages / this.totalMessages) * 100
                : 0;
            this.errosPercent =
              this.errorMessages > 0
                ? (this.errorMessages / this.totalMessages) * 100
                : 0;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    changeChart() {
      this.type = this.type === 'pie' ? 'line' : 'pie';
    },
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
ul.panels {
  list-style: none;
  font-size: 0;
  text-align: center;
  margin-top: 38px;
}
ul.panels li.request {
  color: #246201;
}
ul.panels li {
  display: inline-block;
  list-style: none;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.03);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.09);
  height: 97px;
  min-width: 138px;
  padding: 0 10px;
  position: relative;
  vertical-align: top;
  z-index: 5;
  margin: 6px;
}
ul.panels li h2 {
  font-weight: 600;
  font-size: 11px;
  color: #8c9392;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 1px solid #f7f7f7;
  padding: 7px 0;
  margin: 5px 15px 0;
}
ul.panels li div.single {
  font-size: 31px;
  line-height: 37px;
  margin-top: 14px;
}
ul.panels li div {
  font-weight: 300;
}
ul.panels li.deliver .primary {
  color: #bcd514;
}
ul.panels li div.primary {
  font-size: 24px;
  line-height: 29px;
  margin-top: 10px;
}
ul.panels li.deliver .secondary {
  color: #6a780b;
}
ul.panels li div.secondary {
  font-size: 13px;
  line-height: 16px;
}
</style>
