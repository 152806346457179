<template>
  <!-- <div class="card" :class="{ 'card-expand': expanded }">
    <div class="card-header">
      Dispositivos por Abertura
      <ul class="actions top-right">
        <li>
          <a href="javascript:void(0)" @click="changeChart">
            <i v-if="type == 'line'" class="icon dripicons-graph-pie"></i>
            <i v-else-if="type == 'pie'" class="icon dripicons-graph-line"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="fetch">
            <i class="icon dripicons-clockwise"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="expand">
            <i v-if="expanded" class="icon dripicons-contract-2"></i>
            <i v-else class="icon dripicons-expand-2"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class>
      <div class="card-body block-el">
        <div class="row m-0 col-border-xl" v-if="fetched">
          <div class="col-2 col-md-3 p-20">
            <h6>Total</h6>
            <span class="h3 text-primary">{{ total }}</span>
            <span v-for="(value, device) in devices" :key="device">
              <h6 class="m-t-20">{{ device }}</h6>
              <span class="h3 text-success">{{ value }}</span>
            </span>
          </div>
          <div class="col-10 col-md-9">
            <template v-if="total">
              <line-chart
                v-if="type === 'line'"
                :chartdata="line.chartdata"
                :options="line.options"
                :height="300"
              />
              <doughnut-chart
                v-else-if="type === 'pie'"
                :chartdata="pie.chartdata"
                :options="pie.options"
                :height="300"
              />
            </template>
            <div v-else class="text-center table-placeholder">
              <i class="far fa-chart-bar font-size-80"></i>
              <h5 class="card-title m-t-20">{{$t('sms.infos.none')}}</h5>
              <p>{{$t('sms.infos.none-msg')}}</p>
            </div>
          </div>
        </div>
        <div v-else class="qt-block-ui" />
      </div>
    </div>
  </div> -->
  <div>
    <div>
      <div class="mini-tab-root tab-elevation1 tab-rounded"  style="margin: 10px; width:320px; float:right;">
        <div class="mini-tab-content">
            <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
              <div class="mini-tab-flex">
                  <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                    <span>
                      <i class="fas fa-chart-line"></i>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
                  <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, 160)">
                    <span>
                      <i class="fas fa-chart-pie"></i>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
              </div>
              <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}px;`"></span>
            </div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div class="row" v-if="miniTab.tab == 1">
      <div class="col-sm-6">
        <div>
          <doughnut-chart
            :chartdata="this.doughnut"
            :options="options"
            v-if="fetched"
            :height="200"
          />
        </div>
      </div>
      <div class="col-sm-6" style="padding-right:30px;">
        <br>
        <br>
        <div class="row no-padding no-margin">
          <div class="col-sm-6 no-padding"></div>
          <div class="col-sm-3 no-padding"><strong>Porcentagem</strong></div>
          <div class="col-sm-3 text-right no-padding" style="padding-right:30px;"><strong>Números</strong></div>
        </div>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #1F77B4 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Desktop</span></div>
              <div class="col-sm-3 no-padding">{{this.desktopPercent.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalDesktopData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #AEC7E8 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Telefone</span></div>
              <div class="col-sm-3 no-padding">{{this.phonePercent.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalPhoneData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #FF7F0E !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Tablet</span></div>
              <div class="col-sm-3 no-padding">{{this.tabletPercent.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalTabletData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #FFBB78 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Webmail</span></div>
              <div class="col-sm-3 no-padding">{{this.webMailPercent.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalWebMailData}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-sm-12"><br><br></div>
    </div>
    <div v-if="miniTab.tab == 0">
      <charts
        :data="data"
        :loading="!fetched"
        :xAxis="form.time_window"
        title="Dispositivos por Abertura"
        @update="fetch" />
    </div>
  </div>
</template>

<script>
import MailService from '@/services/mail.service';
// import LineChart from '@/components/LineChart.vue';
// import DoughnutChart from '@/components/DoughnutChart.vue';
import Charts from '@/components/Charts.vue';
import moment from 'moment';
import DoughnutChart from '@/components/DoughnutChart.vue';

export default {
  name: 'DevicesCard',
  // components: { LineChart, DoughnutChart },
  components: {
    Charts,
    DoughnutChart,
  },
  props: ['form', 'filters'],
  data() {
    return {
      options: {
        responsive: true,
        legend: {
          display: false,
        },
      },
      desktopPercent: 0,
      phonePercent: 0,
      tabletPercent: 0,
      webMailPercent: 0,
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      doughnut: {
        labels: [],
        datasets: [
          {
            data: [],
            label: [],
            backgroundColor: [],
          },
        ],
      },
      data: [],
      fetched: false,
      expanded: false,
      type: 'pie',
      colors: ['#2fbfa0', '#ff5c75', '#ffc000'],
      total: 0,
      devices: {},
      line: {
        chartdata: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'HH:mm:ss',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        },
      },
      pie: {
        chartdata: {
          labels: [],
          datasets: [
            {
              backgroundColor: ['#0f0', '#f00', '#00f'],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
      totalDesktopData: 0,
      totalPhoneData: 0,
      totalTabletData: 0,
      totalWebMailData: 0,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
    },
    fetch2() {
      this.fetched = false;
      this.line.chartdata.labels = [];
      this.line.chartdata.datasets = [];
      this.pie.chartdata.labels = [];
      this.pie.chartdata.datasets.backgroundColor = [];
      this.pie.chartdata.datasets.data = [];
      this.devices = {};
      this.total = 0;

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      MailService.getEventInsights({
        filters: [
          {
            field: 'status',
            values: ['open'],
          },
        ],
        ...this.form,
        group_by: 'device_type',
      })
        .then(
          (response) => {
            const devices = {};
            let total = 0;
            response.forEach((element) => {
              Object.keys(element.values).forEach((device, i) => {
                const value = element.values[device];
                if (!this.line.chartdata.datasets[i]) {
                  this.line.chartdata.datasets[i] = {
                    label: device,
                    borderColor: this.colors[i],
                    data: [value],
                    fill: false,
                    lineTension: 0.1,
                  };
                  this.pie.chartdata.labels.push(device);
                  this.pie.chartdata.datasets[0].backgroundColor[
                    i
                  ] = this.colors[i];
                  this.pie.chartdata.datasets[0].data[i] = value;
                  devices[device] = 0;
                } else {
                  this.line.chartdata.datasets[i].data.push(value);
                  this.pie.chartdata.datasets[0].data[i] += value;
                }

                devices[device] += value;
                total += value;
              });
              this.line.chartdata.labels.push(element.date_time);
            });
            this.devices = devices;
            this.total = total;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetch() {
      this.fetched = false;
      this.doughnut.labels = [];
      this.doughnut.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];
      const begin = moment(this.form.begin_date, 'YYYY-MM-DD 00:00:00');
      const end = moment(this.form.end_date, 'YYYY-MM-DD 00:00:00');
      if (end.diff(begin, 'days') <= 1) {
        this.form.time_window = 'hourly';
      } else {
        this.form.time_window = 'daily';
      }

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      MailService.getEventInsights({
        filters: [
          {
            field: 'status',
            values: ['open'],
          },
          ...this.filters,
        ],
        ...this.form,
        group_by: 'device_type',
      })
        .then(
          (response) => {
            let data = [
              ['x'],
              ['Desktop'], // desktopData
              ['Telefone'], // phoneData
              ['Tablet'], // tabletData
              ['Webmail'], // webMailData
            ];
            const maxSeries = 15;
            let series = 0;
            let noData = true;

            console.log(response);

            this.doughnut.labels.push('Desktop %');
            this.doughnut.labels.push('Telefone %');
            this.doughnut.labels.push('Tablet %');
            this.doughnut.labels.push('Webmail %');

            this.totalDesktopData = 0;
            this.totalPhoneData = 0;
            this.totalTabletData = 0;
            this.totalWebMailData = 0;

            response.forEach((element) => {
              const desktopData = element.values.Desktop ?? 0;
              const phoneData = element.values.Phone ?? 0;
              const tabletData = element.values.Tablet ?? 0;
              const webMailData = element.values.Webmail ?? 0;

              this.totalDesktopData += desktopData;
              this.totalPhoneData += phoneData;
              this.totalTabletData += tabletData;
              this.totalWebMailData += webMailData;

              if (!desktopData && !phoneData && !tabletData && !webMailData) {
                series += 1;
              } else {
                noData = false;
                series = 0;
              }

              if (series < maxSeries) {
                data[0].push(element.date_time);
                data[1].push(desktopData ?? 0);
                data[2].push(phoneData ?? 0);
                data[3].push(tabletData ?? 0);
                data[4].push(webMailData ?? 0);
              }
            });

            console.log('Series');
            console.log(series);

            const total = this.totalDesktopData + this.totalPhoneData + this.totalTabletData + this.totalWebMailData;
            this.desktopPercent = this.totalDesktopData > 0 ? (this.totalDesktopData / total) * 100 : 0;
            this.phonePercent = this.totalPhoneData > 0 ? (this.totalPhoneData / total) * 100 : 0;
            this.tabletPercent = this.totalTabletData > 0 ? (this.totalTabletData / total) * 100 : 0;
            this.webMailPercent = this.totalWebMailData > 0 ? (this.totalWebMailData / total) * 100 : 0;

            this.doughnut.datasets[0].data.push(this.desktopPercent.toFixed(2));
            this.doughnut.datasets[0].data.push(this.phonePercent.toFixed(2));
            this.doughnut.datasets[0].data.push(this.tabletPercent.toFixed(2));
            this.doughnut.datasets[0].data.push(this.webMailPercent.toFixed(2));

            this.doughnut.datasets[0].backgroundColor.push('#1F77B4');
            this.doughnut.datasets[0].backgroundColor.push('#AEC7E8');
            this.doughnut.datasets[0].backgroundColor.push('#FF7F0E');
            this.doughnut.datasets[0].backgroundColor.push('#FFBB78');

            if (noData) {
              data = null;
              this.data = [];
            } else {
              this.data = data;
            }
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    changeChart() {
      this.type = this.type === 'pie' ? 'line' : 'pie';
    },
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
</style>
