<template>
  <div>
    <div>
      <div class="mini-tab-root tab-elevation1 tab-rounded"  style="margin: 10px; width:320px; float:right;">
        <div class="mini-tab-content">
            <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
              <div class="mini-tab-flex">
                  <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                    <span>
                      <i class="fas fa-chart-line"></i>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
                  <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, 160)">
                    <span>
                      <i class="fas fa-chart-pie"></i>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
              </div>
              <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}px;`"></span>
            </div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div class="row" v-if="miniTab.tab == 1">
      <div class="col-sm-6">
        <div style="margin-top: 10px;">
          <doughnut-chart
            :chartdata="this.doughnut"
            :options="options"
            v-if="fetched"
            :height="200"
          />
        </div>
      </div>
      <div class="col-sm-6" style="padding-right:30px;">
        <br>
        <br>
        <div class="row no-padding no-margin">
          <div class="col-sm-6 no-padding"></div>
          <div class="col-sm-3 no-padding"><strong>Porcentagem</strong></div>
          <div class="col-sm-3 text-right no-padding" style="padding-right:30px;"><strong>Números</strong></div>
        </div>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #1F77B4 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Descartadas</span></div>
              <div class="col-sm-3 no-padding">{{this.droppedProgress.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalDroppedData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #AEC7E8 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Devolvidos</span></div>
              <div class="col-sm-3 no-padding">{{this.bouncedProgress.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalBouncedData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #00703C !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Entregues</span></div>
              <div class="col-sm-3 no-padding">{{this.deliveredProgress.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalDeliveredData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #FFBB78 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Abertos</span></div>
              <div class="col-sm-3 no-padding">{{this.openedProgress.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalOpenedData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #2CA02C !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Cliques</span></div>
              <div class="col-sm-3 no-padding">{{this.clickedProgress.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalClickedData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #ebd834 !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Cliques Unicos</span></div>
              <div class="col-sm-3 no-padding">{{this.uniqueClickProgress.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalUniqueClickData}}</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="row no-margin" style="width: 100%;">
              <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" style="background: #44c2ad !important;">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">Aberturas Únicas</span></div>
              <div class="col-sm-3 no-padding">{{this.uniqueOpenProgress.toFixed(2)}}%</div>
              <div class="col-sm-3 text-right no-padding" style="padding-right:30px;">{{this.totalUniqueOpenData}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-sm-12"><br><br></div>
    </div>
    <div v-if="miniTab.tab == 0">
      <charts
        :data="data"
        :loading="!fetched"
        :xAxis="form.time_window"
        title="Mensagens por Status"
        @update="fetch" />
    </div>
  </div>
</template>

<script>
import MailService from '@/services/mail.service';
// import LineChart from '@/components/LineChart.vue';
// import DoughnutChart from '@/components/DoughnutChart.vue';
import Charts from '@/components/Charts.vue';
import moment from 'moment';
import DoughnutChart from '@/components/DoughnutChart.vue';
import { LineChart } from 'echarts/charts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { graphic } from 'echarts';

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
]);

export default {
  name: 'OutgoingCard',
  // components: { LineChart, DoughnutChart },
  components: {
    Charts,
    DoughnutChart,
  },
  props: ['form', 'filters'],
  data() {
    return {
      optionReport: {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Email',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: 'Union Ads',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
          },
          {
            name: 'Video Ads',
            type: 'line',
            stack: 'Total',
            data: [150, 232, 201, 154, 190, 330, 410],
          },
          {
            name: 'Direct',
            type: 'line',
            stack: 'Total',
            data: [320, 332, 301, 334, 390, 330, 320],
          },
          {
            name: 'Search Engine',
            type: 'line',
            stack: 'Total',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
      },
      data: [],
      fetched: true,
      expanded: false,
      type: 'line',
      total: 0,
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      doughnut: {
        labels: [],
        datasets: [
          {
            data: [],
            label: [],
            backgroundColor: [],
          },
        ],
      },
      statuses: {},
      line: {
        chartdata: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'HH:mm:ss',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        },
      },
      pie: {
        chartdata: {
          labels: [],
          datasets: [
            {
              backgroundColor: [],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
      droppedProgress: 0,
      bouncedProgress: 0,
      deliveredProgress: 0,
      openedProgress: 0,
      clickedProgress: 0,
      uniqueClickProgress: 0,
      uniqueOpenProgress: 0,
      totalFailedData: 0,
      totalDroppedData: 0,
      totalBouncedData: 0,
      totalDeliveredData: 0,
      totalOpenedData: 0,
      totalClickedData: 0,
      totalSpamReportData: 0,
      totalUniqueClickData: 0,
      totalUniqueOpenData: 0,
    };
  },
  created() {
    // this.fetch();
  },
  provide: {
    [THEME_KEY]: 'light',
  },
  methods: {
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
    },
    fetch2() {
      this.fetched = false;
      this.line.chartdata.labels = [];
      this.line.chartdata.datasets = [];
      this.pie.chartdata.labels = [];
      this.pie.chartdata.datasets.backgroundColor = [];
      this.pie.chartdata.datasets.data = [];
      this.total = 0;

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      MailService.getInsights({
        ...this.form,
        group_by: 'status',
        direction: 'outbound',
        filters: [
          ...this.filters,
        ],
      })
        .then(
          (response) => {
            let total = 0;
            const statuses = {};
            response.forEach((element) => {
              Object.keys(element.values).forEach((status, i) => {
                const value = element.values[status];
                if (!this.line.chartdata.datasets[i]) {
                  let color = '';
                  let label = '';
                  if (status === 'accepted') {
                    color = '#EAF3FD';
                    label = this.$tc('generic-str.status.lbl-pending', 1);
                  } else if (status === 'sent') {
                    color = '#399AF2';
                    label = this.$tc('generic-str.delivered', 2);
                  } else if (status === 'failed') {
                    color = '#ff5c75';
                    label = this.$t('generic-str.status.lbl-errors');
                  } else if (status === 'blocked') {
                    color = '#ee9191';
                    label = this.$t('generic-str.status.lbl-lbl-blocked');
                  } else if (status === 'dropped') {
                    color = '#6e1111';
                    label = this.$tc('generic-str.status.lbl-dropped', 2);
                  } else if (status === 'bounced') {
                    color = '#000';
                    label = this.$tc('generic-str.status.lbl-bounced-o', 2);
                  } else if (status === 'delivered') {
                    color = '#2fbfa0';
                    label = this.$tc('generic-str.delivered', 2);
                  } else if (status === 'opened') {
                    color = '#FFCE67';
                    label = this.$tc('generic-str.status.lbl-open', 2);
                  }
                  this.line.chartdata.datasets[i] = {
                    label,
                    borderColor: color,
                    data: [value],
                    fill: false,
                    lineTension: 0.1,
                  };
                  this.pie.chartdata.labels.push(label);
                  this.pie.chartdata.datasets[0].backgroundColor[i] = color;
                  this.pie.chartdata.datasets[0].data[i] = value;
                } else {
                  this.line.chartdata.datasets[i].data.push(value);
                  this.pie.chartdata.datasets[0].data[i] += value;
                }
                if (!statuses[status]) statuses[status] = 0;
                statuses[status] += value;
                total += value;
              });
              this.line.chartdata.labels.push(element.date_time);
            });
            this.statuses = statuses;
            this.total = total;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetch() {
      this.fetched = false;
      this.doughnut.labels = [];
      this.doughnut.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];
      this.line.chartdata.labels = [];
      this.line.chartdata.datasets = [];
      this.pie.chartdata.labels = [];
      this.pie.chartdata.datasets.backgroundColor = [];
      this.pie.chartdata.datasets.data = [];
      this.total = 0;

      const begin = moment(this.form.begin_date, 'YYYY-MM-DD 00:00:00');
      const end = moment(this.form.end_date, 'YYYY-MM-DD 00:00:00');
      if (end.diff(begin, 'days') <= 1) {
        this.form.time_window = 'hourly';
      } else {
        this.form.time_window = 'daily';
      }

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      console.log('//OHHH');

      MailService.getEventInsights({
        ...this.form,
        group_by: 'status',
        direction: 'outbound',
        filters: [
          ...this.filters,
        ],
      })
        .then(
          (response) => {
            console.log(JSON.stringify(response));
            let data = [
              ['x'],
              // accepted
              // ['Pendente'],
              // [this.$tc('generic-str.sent-a', 2)], // sent
              // [this.$t('generic-str.status.lbl-errors')], // failed
              [this.$tc('generic-str.status.lbl-dropped', 2)], // dropped
              [this.$tc('generic-str.status.lbl-bounced-o', 2)], // bounced
              [this.$tc('generic-str.delivered', 2)], // delivered
              [this.$tc('generic-str.status.lbl-open', 2)], // open
              [this.$tc('generic-str.click', 2)], // click
              [this.$tc('generic-str.unique_open', 2)], // Aberturas Unicas
              [this.$tc('generic-str.unique_click', 2)], // Cliques Unicoss
              // ['Spam Report'], // spamreport
            ];
            const maxSeries = 3;
            let series = 0;
            let noData = true;
            let entregues = 0;

            this.doughnut.labels.push(`${this.$tc('generic-str.status.lbl-dropped', 2)} %`); // Descartadas
            this.doughnut.labels.push(`${this.$tc('generic-str.status.lbl-bounced-o', 2)} %`); // Devolvidos
            this.doughnut.labels.push(`${this.$tc('generic-str.delivered', 2)} %`); // Entregues
            this.doughnut.labels.push(`${this.$tc('generic-str.status.lbl-open', 2)} %`); // Abertos
            this.doughnut.labels.push(`${this.$tc('generic-str.click', 2)} `); // Cliques
            this.doughnut.labels.push(`${this.$tc('generic-str.unique_open', 2)} `); // Aberturas Unicas
            this.doughnut.labels.push(`${this.$tc('generic-str.unique_click', 2)} `); // Cliques Unicos

            this.optionReport.legend.data.push('Descartadas');
            this.optionReport.legend.data.push('Devolvidos');
            this.optionReport.legend.data.push('Entregues');
            this.optionReport.legend.data.push('Abertos');
            this.optionReport.legend.data.push('Cliques');
            this.optionReport.legend.data.push('Spam');
            this.optionReport.legend.data.push('Abertura Únicas');
            this.optionReport.legend.data.push('Cliques Únicos');

            this.optionReport.series.push({
              name: 'Descartadas',
              stack: 'Total',
              type: 'line',
              color: '#1F77B4',
              data: [],
            });
            this.optionReport.series.push({
              name: 'Devolvidos',
              stack: 'Total',
              type: 'line',
              color: '#AEC7E8',
              data: [],
            });
            this.optionReport.series.push({
              name: 'Entregues',
              stack: 'Total',
              type: 'line',
              color: '#00703C',
              data: [],
            });
            this.optionReport.series.push({
              name: 'Abertos',
              stack: 'Total',
              type: 'line',
              color: '#FFBB78',
              data: [],
            });
            this.optionReport.series.push({
              name: 'Cliques',
              stack: 'Total',
              type: 'line',
              color: '#2CA02C',
              data: [],
            });
            this.optionReport.series.push({
              name: 'Spam',
              stack: 'Total',
              type: 'line',
              color: '#ebd834',
              data: [],
            });

            // Info
            // const sentData = element.values.sent ?? 0;
            this.totalFailedData = 0;
            this.totalDroppedData = 0;
            this.totalBouncedData = 0;
            this.totalDeliveredData = 0;
            this.totalOpenedData = 0;
            this.totalClickedData = 0;
            this.totalSpamReportData = 0;
            this.totalUniqueClickData = 0;
            this.totalUniqueOpenData = 0;

            response.forEach((element) => {
              console.log('Eventos', element);
              // const sentData = element.values.sent ?? 0;
              const failedData = element.values.failed ?? 0;
              const droppedData = element.values.dropped ?? 0;
              const bouncedData = element.values.bounce ?? 0;
              const deliveredData = element.values.delivered ?? 0;
              const openedData = element.values.open ?? 0;
              const clickedData = element.values.click ?? 0;
              const spamReportData = element.values.spamreport ?? 0;
              const uniqueOpenData = element.values.unique_open ?? 0;
              const uniqueClickData = element.values.unique_click ?? 0;

              this.totalFailedData += failedData;
              this.totalDroppedData += droppedData;
              this.totalBouncedData += bouncedData;
              this.totalDeliveredData += deliveredData;
              this.totalOpenedData += openedData;
              this.totalClickedData += clickedData;
              this.totalSpamReportData += spamReportData;
              this.totalUniqueOpenData += uniqueOpenData;
              this.totalUniqueClickData += uniqueClickData;

              this.optionReport.series[0].data.push(droppedData);
              this.optionReport.series[1].data.push(bouncedData);
              this.optionReport.series[2].data.push(deliveredData);
              this.optionReport.series[3].data.push(openedData);
              this.optionReport.series[4].data.push(clickedData);
              this.optionReport.series[5].data.push(spamReportData);

              entregues += element.values.delivered;
              if (!failedData && !droppedData && !bouncedData && !deliveredData && !openedData && !clickedData && !spamReportData && !uniqueOpenData && !uniqueClickData) {
                series += 1;
              } else {
                noData = false;
                series = 0;
              }

              if (series < maxSeries) {
                console.log('element', element);
                data[0].push(element.date_time);
                // data[1].push(sentData ?? 0);
                data[1].push(droppedData ?? 0);
                // data[2].push(failedData ?? 0);
                data[2].push(bouncedData ?? 0);
                data[3].push(deliveredData ?? 0);
                data[4].push(openedData ?? 0);
                data[5].push(clickedData ?? 0);
                data[6].push(uniqueClickData ?? 0);
                data[7].push(uniqueOpenData ?? 0);
                // data[7].push(spamReportData ?? 0);
              }
            });
            // Data set
            const total = this.totalDroppedData + this.totalBouncedData + this.totalDeliveredData + this.totalOpenedData + this.totalClickedData + this.totalUniqueClickData + this.totalUniqueOpenData;
            this.droppedProgress = this.totalDroppedData > 0 ? (this.totalDroppedData / total) * 100 : 0;
            this.bouncedProgress = this.totalBouncedData > 0 ? (this.totalBouncedData / total) * 100 : 0;
            this.deliveredProgress = this.totalDeliveredData > 0 ? (this.totalDeliveredData / total) * 100 : 0;
            this.openedProgress = this.totalOpenedData > 0 ? (this.totalOpenedData / total) * 100 : 0;
            this.clickedProgress = this.totalClickedData > 0 ? (this.totalClickedData / total) * 100 : 0;
            this.uniqueClickProgress = this.totalUniqueClickData > 0 ? (this.totalUniqueClickData / total) * 100 : 0;
            this.uniqueOpenProgress = this.totalUniqueOpenData > 0 ? (this.totalUniqueOpenData / total) * 100 : 0;
            this.doughnut.datasets[0].data.push(this.droppedProgress.toFixed(2));
            this.doughnut.datasets[0].data.push(this.bouncedProgress.toFixed(2));
            this.doughnut.datasets[0].data.push(this.deliveredProgress.toFixed(2));
            this.doughnut.datasets[0].data.push(this.openedProgress.toFixed(2));
            this.doughnut.datasets[0].data.push(this.clickedProgress.toFixed(2));
            this.doughnut.datasets[0].data.push(this.uniqueClickProgress.toFixed(2));
            this.doughnut.datasets[0].data.push(this.uniqueOpenProgress.toFixed(2));
            this.doughnut.datasets[0].backgroundColor.push('#1F77B4');
            this.doughnut.datasets[0].backgroundColor.push('#AEC7E8');
            this.doughnut.datasets[0].backgroundColor.push('#00703C');
            this.doughnut.datasets[0].backgroundColor.push('#FFBB78');
            this.doughnut.datasets[0].backgroundColor.push('#2CA02C');
            this.doughnut.datasets[0].backgroundColor.push('#ebd834');
            this.doughnut.datasets[0].backgroundColor.push('#44c2ad');

            this.statuses = [];
            this.total = 0;

            if (noData) {
              data = null;
              this.data = [];
            } else {
              this.data = data;
            }

            console.log('OutgoingCard', { data });
            console.log('Entregues', entregues);
            console.log('Doughnut', this.doughnut);
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    changeChart() {
      this.type = this.type === 'pie' ? 'line' : 'pie';
    },
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
.chart-report {
  height: 500px;
  width: 100%;
}
.chart-report div {
  width: 100% !important;
}
</style>
