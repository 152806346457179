<template>
  <section class="card" :class="{'card-expand':expanded}">
    <header class="card-header">
      <div class="title">
        {{title}}
      </div>
      <div class="acts">
        <button class="btn" @click="update">
          <i class="icon dripicons-clockwise"></i>
        </button>
        <button class="btn" @click="expand">
          <i v-if="expanded" class="icon dripicons-contract-2"></i>
          <i v-else class="icon dripicons-expand-2"></i>
        </button>
        <b-button variant="outline-primary" @click="exports" v-if="data.json || data.length">{{$t('generic-str.export')}}</b-button>
        <b-dropdown right variant="outline-primary" v-if="options !== null">
          <template #button-content>
            <template v-for="(item, index) of options">
              <i
              v-if="item[2]"
              class="opt-icon"
              :style="{background: item[1]}"
              :key="index"
              ></i>
            </template>
            <span class="opt-placeholder">Nenhum filtro selecionado</span>
          </template>
          <b-dropdown-form>
            <!-- <label class="dropdown-item" :key="index">
              <input type="checkbox" v-model="allCharts" :id="index" @change="toggleAll">
              Todos
            </label> -->
            <template v-for="(item, index) of options">
              <label class="dropdown-item" :key="index">
                <input type="checkbox" v-model="item[2]" :id="index" @change="toggleChart(item)">
                <i class="opt-icon" :style="{background: item[1]}"></i>
                {{item[0]}}
              </label>
            </template>
          </b-dropdown-form>
        </b-dropdown>
      </div>
    </header>
    <div class="qt-block-ui" v-if="loading"></div>
    <div v-else>
      <div :class="{myCharts: true, hide:!data.length && !data.json}">
        <div ref="charts"></div>
      </div>
      <div class="text-center table-placeholder" v-if="!data.length && !data.json">
        <i class="far fa-chart-bar font-size-80"></i>
        <h5 class="card-title m-t-20">{{$t('sms.infos.none')}}</h5>
        <p>{{$t('warnings.msg-text-warning')}}</p>
      </div>
      <div class="table-content" v-else-if="worksheet !== null && worksheet.length > 0">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <template v-for="(item, index) of worksheet[0]">
                <th :key="index">{{item}}</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) of worksheet">
              <tr :key="i" v-if="i > 0">
                <template v-if="i === 0">
                  <template v-for="(col, j) of item">
                    <th :key="j">{{col}}</th>
                  </template>
                </template>
                <template v-else>
                  <template v-for="(col, j) of item">
                    <td :key="j">{{xAxis === 'hourly' && j === 0 && col !== 'Total' ? col.substring(11) : col}}</td>
                  </template>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <a href="#" class="hide-link" ref="linkDownloader">Download</a>
  </section>
</template>
<script>
import c3 from 'c3';

export default {
  name: 'Charts',
  props: ['loading', 'xAxis', 'data', 'title'],
  watch: {
    xAxis(newVal) {
      this.xAxis = newVal;
    },
    data(newVal) {
      this.data = newVal;
      this.setDataDelay();
    },
    loading(newVal) {
      this.loading = newVal;
    },
    title(newVal) {
      this.title = newVal;
    },
    empty(newVal) {
      this.empty = newVal;
    },
  },
  mounted() {
    this.setDataDelay();
  },
  data() {
    return {
      empty: false, // Allow empty data
      worksheet: null,
      timeout: 0,
      fetched: false,
      chart: null,
      show: false,
      options: null,
      expanded: false,
      colors: [
        '#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c',
        '#98df8a', '#ffcc00', '#ff9896', '#9467bd', '#c5b0d5',
        '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f',
        '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5',
      ],
    };
  },
  methods: {
    setUpdateData() {
      const data = this.data;
      const hourly = this.xAxis === 'hourly';
      const y = data.length;

      if (y > 2) {
        const worksheet = [];
        const total = ['Total'];
        const j = data[0].length;

        for (let x = 0; x < y; x += 1) {
          for (let i = 0; i < j; i += 1) {
            const item = data[x][i];
            if (x === 0) {
              worksheet.push([item]);
            } else {
              worksheet[i].push(item);
              if (i > 0 && x > 0) total[x] = (total[x] ?? 0) + item;
            }
          }
        }
        this.worksheet = worksheet.filter((item, index) => {
          if (index === 0) return true;

          for (let i = item.length - 1; i > 0; i -= 1) {
            const current = item[i];
            if (current !== 0 && current !== '0') return true;
          }

          return false;
        });
        this.worksheet.push(total);
      } else {
        this.worksheet = [];
      }
    },
    exports() {
      if (this.worksheet !== null) {
        let output = '';
        const worksheet = this.worksheet;
        const j = worksheet.length;
        for (let i = 0; i < j; i += 1) {
          const row = worksheet[i];
          const y = row.length;
          for (let x = 0; x < y; x += 1) {
            const cell = row[x];
            // console.log(`cell: [${cell}]`);
            if (x > 0) output += ';';
            if (
              typeof cell === 'string' &&
              (
                cell.indexOf(' ') !== -1 ||
                cell.indexOf('\t') !== -1 ||
                cell.indexOf(';') !== -1 ||
                cell.indexOf('-') !== -1 ||
                cell.indexOf(':') !== -1
              )
            ) {
              output += `="${cell}"`;
            } else {
              output += cell;
            }
          }
          output += '\r\n';
        }

        this.download(output);
        // console.log(output);
      }
    },
    download(data) {
      const url = URL.createObjectURL(new Blob([data]));
      const link = this.$refs.linkDownloader;
      const title = this.title.replace(/[\s_]+/g, '-');

      link.href = url;
      link.download = `${title}.csv`;
      link.click();

      setTimeout(() => { URL.revokeObjectURL(url); }, 10000);
    },
    expand() {
      this.expanded = !this.expanded;
    },
    update() {
      this.$emit('update');
    },
    // toggleAll() {
    //   for (const opt of this.options) {
    //   }
    // },
    toggleChart(item) {
      if (item[2]) {
        this.chart.show(item[0]);
      } else {
        this.chart.hide(item[0]);
      }
    },
    setDataDelay() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.setData(), 100);
    },
    setData() {
      // const data = [];
      // const g = () => Math.floor(Math.random() * 1000);

      // for (let i = 1; i <= 10; i += 1) {
      //   data.push([`data ${i}`, g(), g(), g(), g(), g(), g(), g(), g(), g(), g(), g(), g()]);
      // }

      // this.chart = c3.generate({
      //   bindto: this.$refs.charts,
      //   data: {
      //     columns: data,
      //   },
      //   grid: {
      //     y: {
      //       show: true,
      //     },
      //   },
      //   line: {
      //     connectNull: true,
      //   },
      //   point: {
      //     show: false,
      //   },
      //   legend: {
      //     show: false,
      //   },
      // });

      // console.log('>>>this.chart', this.chart, this.data);

      if (this.chart !== null) this.chart.revert();

      if (this.data.json || this.data.length) {
        if (this.data.length) {
          let hasError = false;
          let hasSuccess = false;
          const y = this.data[0].length;
          const j = this.data.length;
          // for (let x = 1; x < y; x += 1) {
          //   this.data[0][x] = new Date(this.data[0][x]) ?? this.data[0][x];
          // }
          for (let i = 0; i < this.data.length; i += 1) {
            const label = this.data[i][0].toLowerCase();
            if (label !== 'x') {
              if (!hasError && (
                label.indexOf('erro') !== -1 ||
                label.indexOf('fail') !== -1 ||
                label.indexOf('falh') === 0
              )) {
                // error
                hasError = true;
                this.colors[i - 1] = '#CF202A';
              }
              if (!hasSuccess && (
                label.indexOf('succes') !== -1 ||
                label.indexOf('sucess') !== -1 ||
                label.indexOf('tarifad') === 0 ||
                label.indexOf('entregue') === 0
              )) {
                // green
                hasSuccess = true;
                this.colors[i - 1] = '#00703c';
              }
            }
          }
        }

        // const data = this.data.json ? this.data : {
        //   x: 'x',
        //   columns: this.data,
        // };

        // console.log('//this.xAxis', this.xAxis);

        // if (this.xAxis === 'daily') {
        //   data.xFormat = '%Y-%m-%d';
        // } else if (this.xAxis === 'hourly') {
        //   data.xFormat = '%H:%M:%S';
        // }

        const xAxis = this.xAxis;
        const data = this.data;

        // if (data.length > 1) {
        //   data = data.filter((item) => {
        //     for (let i = item.length - 1; i >= 0; i -= 1) {
        //       if (item[i] !== 0) return true;
        //     }
        //     return false;
        //   });
        // }

        this.chart = c3.generate({
          data: {
            x: 'x',
            columns: data,
            xFormat: '%Y-%m-%d %H:%M:%S',
            order: null,
          },
          bindto: this.$refs.charts,
          color: {
            pattern: this.colors,
          },
          // tooltip de total
          // tooltip: {
          //   contents(data, titleFormat, valueFormat, color) {
          //     let total = 0;
          //     data.forEach((item) => {
          //       total += item.value;
          //     });
          //     const lastItem = data[data.length - 1];
          //     data.push({
          //       x: lastItem.x,
          //       value: total,
          //       id: 'Total',
          //       name: 'Total',
          //       index: lastItem.index + 1,
          //     });
          //     return this.getTooltipContent(data, titleFormat, valueFormat, color);
          //   },
          // },
          // tooltip de data
          // tooltip: {
          //   format: {
          //     title: (x, index) => {
          //       let label;

          //       if (data.json) {
          //         label = data.json[index].name;
          //       } else {
          //         label = data.columns[0][index + 1];
          //       }

          //       if (label instanceof Date) {
          //         label = label.toISOString().split('T');
          //       } else {
          //         label = label.split(' ');
          //       }

          //       if (xAxis === 'daily') return label[0];

          //       if (xAxis === 'hourly') return label[1].substring(0, 5);

          //       return label;
          //     },
          //   },
          // },
          axis: {
            // x: {
            //   type: 'category',
            //   padding: { left: 0, right: 0 },
            // },
            x: {
              type: 'timeseries',
              tick: { format: '%Y-%m-%d %H:%M' },
            },
            y: {
              min: 0,
              padding: { top: 0, bottom: 0 },
            },
          },
          grid: {
            y: {
              show: true,
            },
          },
          line: {
            connectNull: true,
          },
          point: {
            show: false,
          },
          legend: {
            show: false,
          },
        });

        this.setUpdateData();

        this.options = Object.entries(this.chart.data.colors()).map((obj) => {
          obj.push(true);
          return obj;
        }).sort((a, b) => {
          if (b[0] < a[0]) return 1;
          if (b[0] > a[0]) return -1;
          return 0;
        });
      } else {
        this.options = null;
      }

      this.started = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table-content {
  overflow: auto;
  width: 100%;

  &::v-deep {
    // table > thead {
    //   display: none;
    // }

    table > tbody > :first-child {
      white-space: nowrap;
    }

    table tr > :first-child {
      text-align: left;
    }

    table tr > :not(:first-child) {
      text-align: center;
    }

    table > thead > tr:first-child > :first-child {
      font-size: 0;
      opacity: 0;
    }
  }
}

.hide-link {
  position: fixed;
  top: -9999px;
  left: -9999px;
}

.card {
  position: relative;
  min-height: 300px;
  transition: .15s ease;

  &.card-expand {
    position: fixed;
  }
}

.qt-block-ui {
  position: absolute !important;
  z-index: 10;
}

.btn {
  border-radius: .25rem 0 0 .25rem;
}

section {
  .card-header {
    & > .title {
      padding-top: .4em;
      float: left;
    }

    & > .acts {
      text-align: right;

      & > * {
        display: inline-block;
      }

      & > button {
        padding: 8px;
      }
    }
  }

  .myCharts {
    max-height: 10000px !important;
    min-height: 320px !important;
    max-height: 640px !important;
    overflow: auto;
    overflow-y: hidden;

    & > div {
      max-height: inherit !important;
      min-height: inherit !important;
      height: 320px !important;
      min-width: 600px;
      padding: .01em;

      &::v-deep {
        & > svg {
          height: 100% !important;
          margin: .5em;
          width: 98%;

          line, path, circle {
            fill: transparent;
          }
        }

        .c3-tooltip {
          border: thin solid #ccc;
        }

        .c3-tooltip tr, .c3-tooltip td {
          border: none;
        }

        .c3-tooltip td > span {
          border-radius: 16px;
        }

        .c3-line {
          stroke-width: 3px;
        }

        .c3-ygrid {
          stroke: #fbfbfb !important;
          stroke-dasharray: 0;
        }

        .c3-tooltip-container {
          top: 5px !important;
          transition: .12s;
        }

        .c3-axis line, .c3-axis path {
          stroke: #b3c5d9 !important;
        }

        .c3-axis-y line,
        .c3-axis-y path,
        .c3-axis-y .domain {
          stroke-width: 0;
        }

        .c3-axis-y2, .c3-axis-x {
          display: none;
        }

        .tick text {
          fill: #686868;
        }
      }
    }
  }
}

.opt-icon::v-deep {
  display: inline-block;
  margin: 0 2px;
  border-radius: 6px;
  height: 12px;
  width: 12px;
}

.b-dropdown::v-deep {
  display: inline-flex !important;
  float: right;

  .opt-placeholder {
    color: #0c0c0c !important;
  }

  .opt-icon ~ .opt-placeholder {
    display: none;
  }

  .dropdown-toggle {
    background: #fff !important;
    padding: 6px 12px !important;

    &::after {
      border-top-color: #000;
    }
  }

  .b-dropdown-form {
    overflow: auto;
    overflow-x: hidden;
    height: 200px;
  }

  .dropdown-item {
    .opt-icon {
      margin: 0 7px;
    }

    & > * {
      vertical-align: middle;
    }
  }
}
</style>
